<template>
  <div class="uk-container full-page">
    <UserHeader />
    <h1>Receive Test Sample</h1>
    <form @submit.prevent="register">
      <div v-if="error">
        <p class="error">{{ error }}</p>
      </div>
      <div class="uk-margin uk-width-1-3">
        <label for="kitId">Kit Id</label>
        <input
          class="uk-input"
          type="text"
          v-model="kitId"
          :class="{ 'error': !kitId && submitted }"
        />
      </div>
      <div class="uk-margin-top">
        <button class="uk-button uk-button-primary" @click="receiveTest" :disabled="loading">
          Mark Received
          <span v-if="loading" class="uk-margin-small-left" uk-spinner="ratio: 0.6"></span>
        </button>
      </div>
      <div v-if="message" class="uk-margin-top">
        <h3>{{ message }}</h3>
      </div>
      <div class="uk-child-width-expand@s uk-grid" v-if="item">        
        <div class="columns">
          <label>Name</label>
          <div>
            {{ item.FirstName || (item.PetName) }} {{ item.LastName }}
            <div v-if="item.PetType">({{ item.PetType }})</div>
          </div>
        </div>          
        <div class="columns">
          <label>Kit ID</label>
          <div>{{ item.SerialNumber || 'N/A' }}</div>
        </div>
        <div class="columns">
          <label>Kit Type</label>
          <div>{{ item.TestKitType }} - {{ item.TypeName }}</div>
        </div>
        <div class="columns">
          <label>Registered On</label>
          <div v-if="item.RegisteredOnUtc">
             {{ convertUtcToLocal(item.RegisteredOnUtc) }}
          </div>
          <div v-else>--</div>
        </div>
        <div class="columns">
          <label>Received On</label>
          <div v-if="item.ReceivedDateUtc">
            {{ convertUtcToLocal(item.ReceivedDateUtc) }}
          </div>
          <div v-else>--</div>
        </div>
      </div>      
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import UserHeader from './UserHeader.vue';
import { convertUtcToLocal } from '@/utils/common';

export default {
  created() {
    document.title = 'Test Sample Received | Admin | UCARI';
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');
  },
  data() {
    return {
      kitId: '',
      loading: false,
      error: '',
      message: '',
      submitted: false,
      isAuthenticated: false,
      cid: '',
      accessToken: ''      
    };
  },
  components: {
    UserHeader
  },
  methods: {
    async receiveTest() {
      this.submitted = true;
      if (!this.kitId) return;

      this.loading = true;
      this.message = '';
      this.error = '';

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/admin/mark-received/${this.kitId}`, {}, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        if (response.status !== 200) {
          throw new Error(response.data.error || 'Failed to mark item as received.');
        }

        this.message = 'Kit marked as received';
        this.kitId = '';
        this.item = response.data;
      } catch (error) {
        console.error('Error:', error);
        this.error = `Error: ${error.response?.data?.error || error.message}`;
      } finally {
        this.loading = false;
        this.submitted = false;
      }
    },
    convertUtcToLocal
  }
};
</script>
